import { SelectedVehicle } from "../../types"

declare const goToSelectMachine: () => void;
declare const clearMachine: () => void;

export function MachineSelectorButton({ vehicle, shouldRedirectBack = false }: { vehicle: SelectedVehicle | null, shouldRedirectBack?: boolean }) {
    const translations = (window as any).app.preloadState.translation;
    const machineSelectionLink = (window as any).app.preloadState.machineSelectionLink + (shouldRedirectBack ? "?goBack=true" : "");
    const redirectSelectMachine = () => location.assign(machineSelectionLink);
    if (vehicle == null) {
        return <a className="closeMachineButton btn btn--primary btn--primary--inverted" onClick={redirectSelectMachine} >
            {translations["machineSelectionButton.caption"]}
            <span className="selectMachineIcon"></span>
        </a>
    } else {
        return <span className="closeMachineButton btn btn--primary btn--primary--inverted">
            <a href={machineSelectionLink} className="machineName explodedViewMachineSelector"> {vehicle.displayName} | #{vehicle.serialNumber} </a>
            <a onClick={clearMachine} className="closeMachineIcon"></a>
        </span>
    }
}