import "./types";
import { MachineSelectorButton } from 'ui';

function App() {
	const vehicle = window.app.preloadState.selectedVehicle;

    return <MachineSelectorButton vehicle={vehicle} />
}

export default App;
